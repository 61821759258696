<template>
    <div class="_cont">
        <div class="_html" v-html="content" ref="myHtml"></div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: String
        }
    },
    methods: {
        changeImg() {

            this.$scallHtmlImg()
        }
    }
}
</script>

<style lang="scss" scoped>
._cont
{
    margin-top: 40px;
    margin-bottom: 180px;

    // h4
    // {
    //     font-size: 40px;
    //     color: #333;
    //     text-align: center;
    //     margin-bottom: 60px;
    // }

    // p
    // {
    //     text-indent: 2em;
    //     line-height: 30px;
    //     font-size: 20px;
    //     letter-spacing: 1px;
    // }
}
</style>