<template>
    <div class="_cont">
        <div class="_list">
            <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
                <div class="_left">
                    <img :src="$IMG_URL + item.cover" alt="八路军驻洛办事处纪念馆-官方网站">
                </div>
                <div class="_right">
                    <div class="_top">
                        <!-- <div class="_time _impact">{{item.release_date}}</div> -->
                        <h4>{{ item.title }}</h4>
                    </div>

                    <div class="_bo">
                        <p class="_des">{{ item.description }}
                        </p>

                        <div class="_img">
                            <div>
                                <img src="@/assets/image/information-img/news_icon_details.png" alt="八路军驻洛办事处纪念馆-官方网站">
                                <p>在线阅读</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>

export default {
    props: {
        list: {
            type: Array
        }
    },
    methods: {
        goDetail(item) {
            let url = location.origin
            console.log(url)
            window.open(`${url}/detail_pdf?id=${item.id}&breadName=${item.title}`)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../common/mixin.scss";

._cont
{
    margin-bottom: 83px;
    margin-top: 66px;

    ._list
    {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        ._li:hover>._right ._top h4
        {
            color: $hover-color;
        }

        ._li
        {
            display: flex;
            width: 48.6%;
            background-color: #fff;
            margin-bottom: 60px;
            padding: 27px 47px 31px 27px;
            cursor: pointer;

            ._left
            {
                width: 40%;
                margin-right: 37px;
                overflow: hidden;
                img
                {
                    width: 246px;
                    height: 347px;
                    object-fit: cover;
                    transition: all .5s;

                }
                img:hover{
                    transform: scale(1.1);
                }
            }

            ._right
            {
                width: 55%;

                ._top
                {
                    padding-bottom: 24px;
                    border-bottom: 1px solid #EEEEEE;

                    ._time
                    {
                        color: #999;
                        margin-bottom: 15px;

                    }

                    h4
                    {
                        color: #333;
                        font-size: 24px;


                    }
                }

                ._bo
                {
                    margin-top: 24px;

                    ._des
                    {
                        font-size: 18px;
                        color: #999;
                        width: 100%;
                        height: 200px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 8;
                        /* 设置最大显示行数 */
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                    }

                    ._img
                    {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 20px;


                        p
                        {
                            color: #B92D2D;
                        }

                    }
                }
            }
        }
    }
}


@include respondTo('phone'){
    ._cont{
        margin-top: 20px;
        margin-bottom: 0;
        ._list{
            display: block;
            ._li{
                padding: 20px;
                width: 100%;
                margin-bottom: 20px;
                ._left{
                    width: 180px;
                    height: 200px;
                    margin-right: 10px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                ._right{
                    flex: 1;
                    width: auto;

                    ._top{
                        padding-bottom: 10px;
                        h4{
                            font-size: 20px;
                        }
                    }
                    ._bo{
                        margin-top: 10px;
                        ._des{
                            height: 150px;
                            -webkit-line-clamp:7;
                            font-size: 16px;
                        }
                        ._img{
                            margin-top: 10px;
                        }
                    }

                }
            }
        }
    }
}
</style>