<template>
    <div class="_conetntList">
        <div class="_li" v-for="(item, index) in list" :key="index" @click="goDetail(item,'学术活动')">
            <div class="_left">
                <img :src="$IMG_URL + item.cover" alt="八路军驻洛办事处纪念馆-官方网站" class="_leftImg">
            </div>
            <div class="_right">
                <h4>{{item.title}}</h4>
                <div class="_cont">
                    <p class="_c_p">
                       {{ item.introduction }}
                    </p>

                </div>
                <div class="_l_b">

                    <div class="_ll">
                        <p>
                            <img class="_t_img" src="@/assets/image/study-img/time.png" alt="八路军驻洛办事处纪念馆-官方网站">
                            <span class="_impact">{{item.release_date}}</span>
                            <img class="_i_img" src="@/assets/image/information-img/yj.png" alt="八路军驻洛办事处纪念馆-官方网站">
                            <span class="_impact">浏览量：{{item.hits}}</span>
                        </p>
                        <img src="@/assets/image/information-img/news_icon_details.png" alt="八路军驻洛办事处纪念馆-官方网站">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array
        }
    },
    data() {
        return {

        }
    },
    methods:{
        goDetail(item,type){
            let url = location.origin
            console.log(url)
            window.open(`${url}/studyDetail?id=${item.id}&breadName=${type}`)
        }
    }
}


</script>

<style lang="scss" scoped>
._conetntList
{
    // margin-top: 50px;
    margin-bottom: 120px;

    ._li
    {
        display: flex;
        // align-items: center;
        padding: 25px 27px;
        // border-bottom: 1px solid #eee;
        background-color: #fff;
        margin-bottom: 40px;

        cursor: pointer;
        ._left
        {
            margin-right: 35px;

            img
            {
                width: 324px;
                height: 241px;
                object-fit: cover;
            }
        }
        ._right:hover  >h4{
            color: $hover-color;
        }
        ._right
        {
            h4
            {
                color: #333;
                font-weight: bold;
                font-size: 28px;
                letter-spacing: 1px;
                margin-top: 10px;
            }

            ._cont
            {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 18px 0 59px;


                ._c_p
                {
                    color: #999;
                    letter-spacing: 1px;
                    width: 70%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    /* 设置最大显示行数 */
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    font-size: 18px;
                }


            }

            ._l_b
            {

                ._ll
                {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p
                    {
                        display: flex;
                        align-items: center;

                        img
                        {
                            width: 21px;
                            vertical-align: middle;


                        }

                        // ._t_img{
                        //     width: 21px;
                        //     // height: 16px;

                        // }
                        // ._i_img{
                        //  width: 21px;
                        // }

                        span
                        {
                            color: #999;
                            margin-left: 10px;
                            letter-spacing: 1px;

                            margin-right: 31px;
                        }
                    }

                    img
                    {
                        width: 51px;
                    }
                }
            }
        }
    }
}
</style>