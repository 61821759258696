<template>
  <div class="_wrap">
    <div class="_banner">
      <!-- <img src="@/assets/image/home-img/com_home_banner.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
      <img :src="$IMG_URL + $store.state.webData.top_image.study_list_image" alt="八路军驻洛办事处纪念馆-官方网站">

    </div>

    <div class="_tabs" id="Jump">
      <div class="_auto">
        <div class="_tit">学术</div>
        <!-- <div class="_list">
          <div :class="{ _li: true, _active: current == index }" v-for="(item, index) in tabList" :key="index"
            @click="changeTab(item, index)">{{ item.name }}</div>
        </div> -->
      </div>

    </div>

    <div class="_auto">
      <div class="_bread">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>学术</el-breadcrumb-item>
          <!-- <el-breadcrumb-item>{{ breadTit }}</el-breadcrumb-item> -->
        </el-breadcrumb>
      </div>


      <div class="_allList">

        <!-- <transition>
          <div v-if="current == 0">
            <first ref="firstRef" :content="content"></first>
          </div>
        </transition>


        <transition>

          <div v-if="current == 1">

            <two ref="twoRef" :list="list"></two>
          </div>
        </transition>

        <transition>

          <div v-if="current == 2">

            <three ref="threeRef" :list="cgList"></three>
          </div>
        </transition> -->


        <four_new v-if="total > 0" ref="four_newRef" :list="fourList"></four_new>


        <div class="_nodata" v-else>暂无数据...</div>
      </div>



      <div class="_page">
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.page_num"
          @pagination="getNewList" />

      </div>


    </div>




    <mainFooter ref="mainFooterRef"></mainFooter>

  </div>
</template>

<script>
import first from './model/first.vue'
import three from './model/three.vue'
import two from './model/two.vue'

import { academicCommittee, academicActivities, academicAchievements, academic } from '@/api/getData'
import four_new from './model/four_new.vue'
export default {
  components: {
    first,
    two,
    three,
    four_new

  },
  data() {
    return {
      wbTitle: "八路军驻洛办事处纪念馆-学术",
      keywords: "八路军驻洛办事处纪念馆,洛八办",
      desc: "八路军驻洛办事处纪念馆",
      breadTit: "学术委员会",
      flag: false,
      content: "",
      list: [],
      cgList: [],
      fourList: [],
      total: 0,
      queryParams: {
        page: 1,
        page_num: 10,
      },
      current: 0,
      tabList: [
        {
          name: "学术委员会",
        },
        {
          name: "学术活动",
        }, {
          name: "学术成果"
        }
      ],
    }
  },
  // 动态设置页面标题、关键字和描述等属性  
  metaInfo() {

    return {
      title: this.wbTitle,
      // meta: [
      //   { name: "keywords", content: this.keywords },
      //   { name: "description", content: this.desc },
      // ]
    }
  },
  created() {


  },
  // 监听,当路由发生变化的时候执行
  // watch: {
  //   $route(to, from) {
  //     console.log(to);
  //     let title = to.query.tit
  //     this.breadTit = title
  //     if (title) {
  //       if (title == '学术委员会') {
  //         this.current = 0
  //         this.flag = false
  //         this.academicCommittee()
  //       } else if (title == '学术活动') {
  //         this.current = 1
  //         this.flag = true
  //         this.getList()
  //       } else {
  //         this.current = 2
  //         this.flag = false
  //         this.academicAchievements()

  //       }

  //       this.$scrollToSection('Jump')
  //     } else {
  //       window.scrollTo({
  //         top: 0,
  //         behavior: 'smooth' // 平滑滚动
  //       })
  //     }
  //   }
  // },


  async mounted() {

    // let title = this.$route.query.tit

    // if (title) {
    //   this.breadTit = title
    //   if (title == '学术委员会') {
    //     this.current = 0
    //     this.flag = false
    //     await this.academicCommittee()
    //     this.$refs.firstRef.changeImg()
    //   } else if (title == '学术活动') {
    //     this.current = 1
    //     this.flag = true
    //     await this.getList()
    //   } else {
    //     this.current = 2
    //     this.flag = false
    //     await this.academicAchievements()

    //   }

    //   this.$scrollToSection('Jump')

    // } else {
    //   await this.academicCommittee()

    //   this.$refs.firstRef.changeImg()
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth' // 平滑滚动
    //   })
    // }

    await this.getNewList()

    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 平滑滚动
    })



  },
  methods: {
    changeTab(item, index) {
      this.current = index
      this.breadTit = item.name
      if (index == 0) {
        this.flag = false
        this.academicCommittee()
      } else if (index == 1) {
        this.flag = true
        this.getList()
      } else {
        this.flag = false
        this.academicAchievements()
      }
    },
    async getList() {
      await this.academicActivities()
    },

    async academicCommittee() {
      await academicCommittee().then(res => {
        console.log(res)
        this.content = res.data
      })
    },

    async academicActivities() {
      await academicActivities(this.queryParams).then(res => {
        console.log(res)
        this.total = res.data.total
        this.list = res.data.data
      })
    },
    async academicAchievements() {
      await academicAchievements().then(res => {
        console.log(res)

        this.cgList = res.data.data
      })
    },

    async getNewList() {
      await academic(this.queryParams).then(res => {
        this.fourList = res.data.data
        this.total = res.data.total
      })
    },
  }

}
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._page
{
  text-align: center;
  margin-bottom: 65px;
  margin-top: 25px;
}

._wrap
{
  width: 100%;
  overflow: hidden;
  background-color: #FDFAF6;
}

._banner
{
  width: 100%;
  height: 700px;

  img
  {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


}

._nodata{
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
}
._allList
{
  margin-top: 50px;

}

._auto
{
  width: 75%;
  margin: 0 auto;
}

._tabs
{
  width: 100%;
  background-color: #fff;

  // padding: 0px 0;
  ._auto
  {
    display: flex;

  }

  ._tit
  {
    margin-right: 40px;
    color: white;
    // background-color: #B41E1E;
    background-image: url("../../assets/image/information-img/com_bg_title.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // padding: 20px 40px;
    width: 183px;
    height: 90px;
    text-align: center;
    line-height: 90px;
    font-size: 30px;
  }

  ._list
  {
    display: flex;
    align-items: center;

    ._li
    {
      margin: 0 20px;
      color: #333;
      font-weight: bold;
      font-size: 24px;
      padding: 25px 0;
      cursor: pointer;
    }

    ._active
    {
      color: #B41E1E;
      border-bottom: 4px solid #B41E1E;

    }

    // ._active::after{
    //   content: "";
    //   display: inline-block;
    //   width: ;
    // }
  }
}

._bread
{
  margin: 40px 0 20px;
}

::v-deep .el-breadcrumb__inner
{
  color: #969696 !important;
}





.v-enter-active,
.v-leave-active
{
  transition: all .8s ease;
}

.v-enter,
.v-leave-to
{
  transform: translateX(100px);
  opacity: 0;
}


@include respondTo('phone'){

  ._banner{
    height: 300px;
  }
  ._auto{
    padding: 0 8px;
  }
  ._tabs{

    ._auto{
      padding: 0 8px;
      ._tit{
        width: 140px;
        height: 60px;
        line-height: 60px;
        margin-right: 0;
      }
    }
  }
  ._bread{
    margin: 20px 0;

  }

  ._allList{
    margin-top: 20px;
  }
}
</style>