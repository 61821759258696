<template>
    <div class="_cont">
        <div class="_list">
            <div class="_li" v-for="(item,index) in list" :key="index" @click="goDetail(item, '学术成果')">
                <div class="_left">
                    <img :src="item.cover" alt="八路军驻洛办事处纪念馆-官方网站">
                </div>
                <div class="_right">
                    <div class="_top">
                        <div class="_time _impact">{{item.release_date}}</div>
                        <h4>{{item.title}}</h4>
                    </div>

                    <div class="_bo">
                        <p>{{ item.introduction }}
                        </p>

                        <img src="@/assets/image/information-img/news_icon_details.png" alt="八路军驻洛办事处纪念馆-官方网站">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        list:{
            type:Array
        }
    },
    methods: {
        goDetail(item, type) {
            let url = location.origin
            console.log(url)
            window.open(`${url}/studyDetail?id=${item.id}&breadName=${type}`)
        }
    }
}
</script>

<style lang="scss" scoped>
._cont
{
    margin-bottom: 83px;
    margin-top: 66px;

    ._list
    {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        ._li:hover  >._right ._top h4{
            color: $hover-color;
        }
        ._li
        {
            display: flex;
            width: 48.6%;
            background-color: #fff;
            margin-bottom: 60px;
            padding: 27px 47px 31px 27px;
            cursor: pointer;
            ._left
            {
                margin-right: 37px;

                img
                {
                    width: 246px;
                    height: 347px;
                }
            }

            ._right
            {

                ._top
                {
                    padding-bottom: 24px;
                    border-bottom: 1px solid #EEEEEE;

                    ._time
                    {
                        color: #999;
                        margin-bottom: 15px;

                    }

                    h4
                    {
                        color: #333;
                        font-size: 24px;


                    }
                }

                ._bo
                {
                    margin-top: 24px;

                    p
                    {
                        font-size: 18px;
                        color: #999;

                    }

                    img
                    {
                        margin-top: 58px;
                        float: right;

                    }
                }
            }
        }
    }
}</style>